import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_ALL_PLANS = `${REACT_APP_BASE_URL}/v1/admin/plans`;
const PLAN_SETTINGS = `${REACT_APP_BASE_URL}/v1/admin/plans/settings`;
const SET_INVESTOR_PROFILE_PLAN = `${REACT_APP_BASE_URL}/v1/admin/plans/investment-profile-plan`;
const FETCH_PLAN_DETAILS = `${REACT_APP_BASE_URL}/v1/admin/plans`;
const PAY_DIVIDENDS = `${REACT_APP_BASE_URL}/v1/admin/plans/pay-dividends`;

const fetchAllPlans = async (investable, fundType) => {
  return axios.get(FETCH_ALL_PLANS, {
    params: {
      ...(fundType && { fundType }),
      ...(investable && { investable }),
    },
  });
};

const planSettings = async plans => {
  const res = await axios.put(PLAN_SETTINGS, {
    plans,
  });
  return res;
};

const getPlanDetails = async (planId, page, limit) => {
  return axios.get(
    `${FETCH_ALL_PLANS}/${planId}/users?page=${page}&limit=${limit}`,
  );
};

const investorProfilePlanSettings = async investorsProfile => {
  const res = await axios.put(SET_INVESTOR_PROFILE_PLAN, investorsProfile);
  return res;
};

const fetchPlanDetails = async planId => {
  const res = await axios.get(`${FETCH_PLAN_DETAILS}/${planId}`);
  return res;
};

const payDividends = async payload => {
  const res = await axios.post(`${PAY_DIVIDENDS}`, { ...payload });
  return res;
};

export {
  fetchAllPlans,
  fetchPlanDetails,
  getPlanDetails,
  investorProfilePlanSettings,
  payDividends,
  planSettings,
};
