import React, { useState } from 'react';

import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

function getModalStyle() {
  const top = 50;
  const left = 55;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '25%',
    maxHeight: '75vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down(768)]: {
      width: '90%',
      marginLeft: '-5%',
    },
  },
}));

const AddTransactionModal = ({
  open,
  handleClose,

  setOpen,
  id,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);
  const transactionSelections = [
    {
      id: 1,
      name: 'Direct Investment',
    },
    { id: 2, name: 'Goal Investment' },
  ];
  const setNextLink = () => {
    setOpen(false);
    history.push({
      pathname: `/investor/${id}/add-transaction`,
      state: {
        data: selectedOption,
      },
    });
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <div className="d-flex justify-content-center font-weight-bolder">
          What do you want to invest in?
        </div>
        <div
          style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }}
          className="mx-auto py-10"
        >
          {transactionSelections &&
            transactionSelections?.length &&
            transactionSelections?.map(transactionSelection => (
              <div
                key={transactionSelection?.id}
                style={{
                  marginBottom: '1rem',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <input
                  type="radio"
                  name={transactionSelection.name}
                  onChange={() => setSelectedOption(transactionSelection.name)}
                  checked={transactionSelection?.name === selectedOption}
                  className="form-check-input"
                  id={transactionSelection?.id}
                />
                <label
                  className="form-check-label font-weight-bold"
                  htmlFor={transactionSelection?.id}
                >
                  {transactionSelection?.name}
                </label>
              </div>
            ))}
        </div>

        <div className="d-flex justify-content-center flex-wrap">
          <button
            type="button"
            className="blue-button"
            style={{
              background: '#dbedf8',
              color: '#3699FF',
              border: 'none',
              height: '40px',
              marginTop: 0,
            }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            style={{
              background: '#0071CE',
              borderRadius: '5px',
              padding: '10px 20px',
              border: '1px solid #0071CE',
              color: '#ffffff',
              height: '40px',
              marginLeft: '5px',
            }}
            onClick={setNextLink}
            disabled={!selectedOption}
          >
            Next
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddTransactionModal;
