import { FileNameTypes } from './constants';

export const getFileName = name => {
  if (name === FileNameTypes.NATIONAL_ID) {
    return 'nationalId';
  } else if (name === FileNameTypes.PHOTO) {
    return 'facePhoto';
  } else if (name === FileNameTypes.TAX_CERTIFICATE) {
    return 'taxPinCertificate';
  } else if (name === 'businessChamaDirectorIdentity') {
    return 'businessChamaDirectorIdentity';
  } else if (name === 'certificateOfRegistration') {
    return 'certificateOfRegistration';
  } else if (name === 'letterOfAuthorization') {
    return 'letterOfAuthorization';
  } else if (name === 'certificateOfIncorporation') {
    return 'certificateOfIncorporation';
  } else if (name === 'CR12Certificate') {
    return 'CR12Certificate';
  } else if (name === 'boardResolutionLetter') {
    return 'boardResolutionLetter';
  } else {
    return 'proofOfAddress';
  }
};
