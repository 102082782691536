export const InvestorDetails = [
  {
    id: 'firstName',
    label: 'Investor Type',
    value: 'type',
    type: 'text',
  },
  {
    id: 'lastName',
    label: 'Investor Name',
    value: 'Name',
    type: 'text',
  },
  {
    id: 'email',
    label: 'Email',
    value: 'Email',
    type: 'email',
  },
  {
    id: 'gender',
    label: 'Gender ',
    value: 'Male',
    type: 'tel',
  },
  {
    id: 'IDtype',
    label: 'ID Type',
    value: 'National Id',
    type: 'number',
  },
  {
    id: 'idNumber',
    label: 'ID Number',
    // value:{userStatus}
    value: '35000000',
    type: 'text',
  },
  {
    id: 'nextOfKin',
    label: 'Next of Kin Name',
    // value:{userStatus}
    value: '35000000',
    type: 'text',
  },
  {
    id: 'nextOfKinNumber',
    label: 'Next of Kin Number',
    // value:{userStatus}
    value: '35000000',
    type: 'tel',
  },
];

export const financialDetails = [
  {
    id: 'taxPayerNumber',
    label: 'Tax Payer Number',
    // value:{firstName}
    value: 'POOOOOOO',
    type: 'text',
  },
  {
    id: 'registrationNumber',
    label: 'Registration Number',
    // value:{lastName}
    value: '456789',
    type: 'text',
  },
  {
    id: 'nameMatch',
    label: 'Name Match',
    // value:{email}
    value: 'Kuresoi Chama ',
    type: 'text',
  },
];

export const investorAddress = [
  {
    id: 'country',
    label: 'Country ',
    value: 'Kenya',
    type: 'text',
  },
  {
    id: 'city',
    label: 'City',
    value: 'Nairobi',
    type: 'text',
  },
  {
    id: 'postalAddress',
    label: 'Postal Address',
    value: '33',
    type: 'text',
  },
  {
    id: 'postalCode',
    label: 'Postal Code',
    value: '20200',
    type: 'text',
  },
  {
    id: 'residentialAddress',
    label: 'Residential Address',
    value: 'Kericho',
    type: 'text',
  },
];

export const otherFields = [
  {
    id: 'dateRegistered',
    label: 'Date Registered',
    value: '2022-08-08',
    type: 'date',
  },
  {
    id: 'dateKYCRegistered',
    label: 'Date KYC Submitted',
    value: '2022-08-08',
    type: 'date',
  },
  {
    id: 'kycRejectedReason',
    label: 'KYC Rejected Reason',
    value: 'No Reason',
    type: 'text',
  },
];

export const FileNameTypes = {
  NATIONAL_ID: 'National ID',
  PHOTO: 'Photo',
  TAX_CERTIFICATE: 'Tax cert',
};
