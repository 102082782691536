import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import ErrorCard from '../../../common/ErrorCard';
import AllKycAuditInfo from './AllKycAudits';
import BackIcon from '../../../icons/backIcon.svg';
import { fetchInvestorHistory } from '../../investors/_redux';

const UserHistory = () => {
  const location = useLocation();
  const { investorId } = useParams();
  const [loading, setLoading] = useState(true);
  const [kyc, setKyc] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const getAuditHistory = async investorId => {
    try {
      setLoading(true);
      const res = await fetchInvestorHistory(investorId);
      if (res.status === 200) {
        let data = res.data.data;
        setKyc(data);
        setLoading(false);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAuditHistory(investorId);
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {errorMessage ? (
        <ErrorCard backUrl={'/dashboard'} errorMessage={errorMessage} />
      ) : (
        <div>
          <Link
            to={
              location.state?.prevUrl ? location.state?.prevUrl : '/dashboard'
            }
          >
            <img src={BackIcon} alt={'Back icon'} />
            <span className="ml-2">Back</span>
          </Link>
          <div className="card card-custom gutter-b mt-3">
            <div className="card-header">
              <h4 className="pt-8">Investor KYC Audit History</h4>
            </div>
            <div className="d-flex justify-content-between flex-wrap my-5 mx-10"></div>
            <div className="card-body mt-3 mb-15 pt-0 pb-3">
              <div className="tab-content">
                <div className="table-responsive">
                  <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                    <thead>
                      <tr className="text-capitalize">
                        <th style={{ minWidth: '150px' }} className="pl-7">
                          <span className="text-dark-75">Investor Email</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Full Name</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Date Registered</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">
                            Date KYC Submitted
                          </span>
                        </th>
                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">
                            Date KYC Evaluated
                          </span>
                        </th>
                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">KYC Status</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {kyc &&
                        kyc.map(kycValue => (
                          <AllKycAuditInfo key={kycValue.id} {...kycValue} />
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserHistory;
