import React, { Suspense, useEffect } from 'react';
import { useClearCache } from 'react-clear-cache';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { ContentRoute, LayoutSplashScreen } from '../_metronic/layout';
import { AdminPermissions } from '../app/helpers/constants';
import ArchivedTransactions from './modules/ArchivedTransactions/pages';
import Blocks from './modules/Blocks/pages';
import FundTransactions from './modules/Blocks/pages/FundTransactions';
import SingleFund from './modules/Blocks/pages/SingleFund';
import CorporateActions from './modules/CorporateActions/pages';
import CorporateActionDetail from './modules/CorporateActions/pages/CorporateActionDetail';
import AllDailyTrades from './modules/DailyTrades/pages';
import InvestorProfile from './modules/Investor-profile/pages';
import AddTransaction from './modules/Investor-profile/pages/AddTransaction';
import AddVoucher from './modules/Investor-profile/pages/AddVoucher';
import InvestorPocketBalance from './modules/Investor-profile/pages/InvestorPocketBalance';
import InvestorPortfolio from './modules/Investor-profile/pages/InvestorPortfolio';
import InvestorTransaction from './modules/Investor-profile/pages/InvestorTransactions';
import Partners from './modules/Partners/pages';
import RecurringPayments from './modules/RecurringPayments/pages';
import SettlementDetails from './modules/SettlementDetails/pages';
import UnassignedTransactions from './modules/UnassignedTransactions/pages';
import UserKyc from './modules/Users-kyc/pages';
import UserHistory from './modules/Users-kyc/pages/History';
import SingleUser from './modules/Users-kyc/pages/SingleUser';
import Admins from './modules/admins/pages';
import CreateAdmin from './modules/admins/pages/CreateAdmin';
import Permissions from './modules/admins/pages/Permissions';
import AdminFX from './modules/conversions/pages';
import Investor from './modules/investors/pages';
import Plans from './modules/plans/pages';
import { PlanDetails } from './modules/plans/pages/PlanDetails';
import SinglePlan from './modules/plans/pages/SinglePlan';
import PocketTransactions from './modules/pockets/pages';
import PaymentBlocks from './modules/pockets/pages/PaymentBlock';
import PaymentBlockTransactions from './modules/pockets/pages/PaymentBlockTransactions';
import PendingWithdrawals from './modules/pockets/pages/PendingWithdrawals';
import ChangePassword from './modules/profile/pages';
import { BuilderPage } from './pages/BuilderPage';
import HomePage from './pages/HomePage';
import SessionTimeout from './utils/sessionTimeout';

const BasePage = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  });
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <SessionTimeout isAuthenticated={true} />
      <Switch>
        <Redirect exact from="/" to="/home" />
        <ContentRoute path="/home" component={HomePage} />
        <ContentRoute
          path="/dashboard"
          component={UserKyc}
          permission={AdminPermissions.USER_VIEW}
        />
        <ContentRoute
          path="/investors"
          component={Investor}
          permission={AdminPermissions.USER_VIEW}
        />
        <ContentRoute
          path="/investor-profile/:investorId"
          component={InvestorProfile}
          permission={AdminPermissions.USER_VIEW}
        />
        <ContentRoute
          path="/users/:investorId"
          component={SingleUser}
          permission={AdminPermissions.USER_VIEW}
        />
        <ContentRoute
          path="/investor-transactions/:investorId"
          component={InvestorTransaction}
          permission={AdminPermissions.TRANSACTION_VIEW}
        />
        <ContentRoute
          path="/investor-portfolio/:investorId"
          component={InvestorPortfolio}
          permission={AdminPermissions.TRANSACTION_VIEW}
        />
        <ContentRoute
          path="/investor-pocket-balance/:id"
          component={InvestorPocketBalance}
          permission={AdminPermissions.TRANSACTION_VIEW}
        />
        <ContentRoute
          path="/investor-audit-history/:investorId"
          component={UserHistory}
          permission={AdminPermissions.USER_VIEW}
        />
        <ContentRoute
          path="/investor/:investorId/add-voucher"
          component={AddVoucher}
          permission={AdminPermissions.BONUS_MANAGEMENT}
        />
        <ContentRoute
          exact
          path="/blocks/partner/:partnerId"
          component={Blocks}
          permission={AdminPermissions.BLOCK_VIEW}
        />
        <ContentRoute
          path="/blocks"
          component={Blocks}
          permission={AdminPermissions.BLOCK_VIEW}
        />
        <ContentRoute path="/change-password" component={ChangePassword} />
        <ContentRoute
          path="/view-fund/:blockId"
          component={SingleFund}
          permission={AdminPermissions.BLOCK_VIEW}
        />
        <ContentRoute
          path="/partners"
          component={Partners}
          permission={AdminPermissions.PARTNER_VIEW}
        />
        <ContentRoute
          exact
          path="/unassigned-transactions/partner/:partnerId"
          component={UnassignedTransactions}
          permission={AdminPermissions.TRANSACTION_VIEW}
        />
        <ContentRoute
          path="/unassigned-transactions"
          component={UnassignedTransactions}
          permission={AdminPermissions.TRANSACTION_VIEW}
        />

        <ContentRoute
          path="/investor/:id/add-transaction"
          component={AddTransaction}
          permission={AdminPermissions.TRANSACTION_VIEW}
        />
        <ContentRoute
          path="/settlement-details"
          component={SettlementDetails}
          permission={AdminPermissions.VIEW_SETTLEMENT_DETAILS}
        />
        <ContentRoute
          path="/archived-transactions"
          component={ArchivedTransactions}
          permission={AdminPermissions.TRANSACTION_VIEW}
        />
        <ContentRoute
          path="/pocket-transactions"
          component={PocketTransactions}
          permission={AdminPermissions.VIEW_POCKETS}
        />
        <ContentRoute
          path="/plan/:planId"
          component={PlanDetails}
          permission={AdminPermissions.FUND_VIEW}
        />
        <ContentRoute
          path="/plans"
          component={Plans}
          permission={AdminPermissions.FUND_VIEW}
        />

        <ContentRoute
          path="/fund/:id/users"
          component={SinglePlan}
          permission={AdminPermissions.FUND_VIEW}
        />
        <ContentRoute
          path="/daily-trades"
          component={AllDailyTrades}
          permission={AdminPermissions.DAILY_TRADE_CONFIGURATION}
        />
        <ContentRoute
          path="/block/:blockId/fund-instance/:fundInstanceId"
          component={FundTransactions}
          permission={AdminPermissions.BLOCK_VIEW}
        />
        <ContentRoute
          path="/admins/permissions/:adminId"
          component={Permissions}
          permission={AdminPermissions.SUPER_ADMIN}
        />
        <ContentRoute
          path="/admins/set-permissions"
          component={Permissions}
          permission={AdminPermissions.SUPER_ADMIN}
        />
        <ContentRoute
          path="/admins"
          component={Admins}
          permission={AdminPermissions.SUPER_ADMIN}
        />
        <ContentRoute
          path="/admin-create"
          component={CreateAdmin}
          permission={AdminPermissions.SUPER_ADMIN}
        />
        <ContentRoute
          path="/payment-batch"
          component={PaymentBlocks}
          permission={AdminPermissions.VIEW_PAYMENT_BATCH}
        />
        {/* PaymentBlockTransactions */}
        <ContentRoute
          path="/payment-block/:blockId"
          component={PaymentBlockTransactions}
          permission={AdminPermissions.VIEW_PAYMENT_BATCH}
        />
        <ContentRoute
          path="/pending-withdrawals"
          component={PendingWithdrawals}
          permission={AdminPermissions.VIEW_POCKETS}
        />
        <ContentRoute
          path="/conversions"
          component={AdminFX}
          permission={AdminPermissions.VIEW_CONVERSIONS_RATE}
        />
        <ContentRoute
          path="/recurring-payments"
          component={RecurringPayments}
          permission={AdminPermissions.VIEW_RECURRING_PAYMENTS}
        />
        <ContentRoute
          path="/corporate-action/plan/:planId"
          component={CorporateActionDetail}
          permission={AdminPermissions.MANIPULATE_CORPORATE_ACTIONS}
        />
        <ContentRoute
          path="/corporate-actions"
          component={CorporateActions}
          permission={AdminPermissions.VIEW_CORPORATE_ACTIONS}
        />

        <ContentRoute path="/builder" component={BuilderPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
};

export default connect(null)(BasePage);
