import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import NumberOfRows from '../../../common/SelectNumberOfRows';
import SelectBox from '../../../common/SelectBox';
import Alert from '../../../common/Alert';
import Swal from 'sweetalert2';
import SVG from 'react-inlinesvg';
import Pagination from '@material-ui/lab/Pagination';
import { fetchAllPartners } from '../../UnassignedTransactions/_redux/transactionsCrud';
import { fetchAllRecurringPayments, searchRecurringPayments } from '../_redux';
import RecurringPaymentTable from './RecurringPaymentTable';
import {
  AdminPermissions,
  recurringPaymentChannels,
  recurringPaymentStatus,
} from '../../../helpers/constants';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { fetchAllPlans } from '../../plans/_redux/planCrud';

const tableHeaders = [
  'ID',
  'Partner',
  'User Email',
  'User Name',
  'Fund Name',
  'Payment Channel',
  'Mpesa number',
  'Amount',
  'currency',
  'Date created',
  'End date',
];

function RecurringPayments() {
  const [recurringPayments, setRecurringPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [loadingData, setLoadingData] = useState(false);
  const [paymentChannel, setSelectedPaymentOption] = useState(null);
  const [partners, setPartners] = useState();
  const [partnerId, setPartnerId] = useState('');
  const [searchItem, setSearchItem] = useState('');
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState(null);
  const [plans, setPlans] = useState([]);
  const [planId, setPlanId] = useState(null);

  const getAllRecurringPayments = async () => {
    try {
      setLoading(true);
      const res = await fetchAllRecurringPayments();
      if (res.status === 200) {
        setRecurringPayments(res.data.items);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      handleAlert(error?.response?.data?.message, 'error');
    }
  };

  const getPlans = async () => {
    try {
      const res = await fetchAllPlans();

      if (res.status === 200) {
        const data = res.data.data.map(plan => ({
          id: plan.id,
          value: plan.id,
          name: `${plan.id}. ${plan.coolName}`,
        }));
        setPlans(data);
      }
    } catch (error) {
      handleAlert(error?.response?.data?.message, 'error');
    }
  };
  useEffect(() => {
    getAllRecurringPayments();
    getPlans();
    getPartners();
  }, []);

  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const handleAlert = (message, iconType) => {
    Swal.fire({
      icon: iconType,
      position: 'top-end',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      text: message,
      showConfirmButton: false,
      timer: 1200,
    });
  };

  const handleChangePage = async (event, value) => {
    const res = await searchRecurringPayments(
      partnerId,
      status,
      paymentChannel,
      planId,
      searchItem,
      value,
      limit,
    );
    setPage(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setRecurringPayments(res.data.items);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    }
  };

  const handleChangeLimit = async event => {
    const { value: selectedValue } = event.target;
    setPage(1);

    const res = await searchRecurringPayments(
      partnerId,
      status,
      paymentChannel,
      planId,
      searchItem,
      1,
      selectedValue,
    );
    setLimit(selectedValue);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setRecurringPayments(res.data.items);
    }
  };

  const getPartners = async () => {
    try {
      const res = await fetchAllPartners();
      if (res.status === 200) {
        setPartners(res.data.data);
      }
    } catch (error) {}
  };

  const handleSearch = async e => {
    const { value } = e.target;
    setPage(1);
    const res = await searchRecurringPayments(
      partnerId,
      status,
      paymentChannel,
      planId,
      value.replace('+', '%2B'),
      1,
      limit,
    );
    setSearchItem(value);

    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setRecurringPayments(res.data.items);
    }
  };

  const searchForRecurringPayments = async (
    partnerId,
    status,
    paymentChannel,
    planId,
    searchItem,
  ) => {
    try {
      if (
        partnerId ||
        status ||
        paymentChannel ||
        planId ||
        searchItem ||
        page ||
        limit
      ) {
        setLoadingData(true);
        const res = await searchRecurringPayments(
          partnerId,
          status,
          paymentChannel,
          planId,
          searchItem,
          page,
          limit,
        );

        if (res.status === 200) {
          setTotalRecord(res.data.meta.totalItems);
          setTotalPages(res.data.meta.totalPages);
          setRecurringPayments(res.data.items);
          setLoadingData(false);
        }
      }
    } catch (error) {
      handleAlert(error.response.data.message, 'error');
      setLoadingData(false);
    }
  };
  const setValue = (val, type, id) => {
    if (type === 'status') {
      setStatus(val);
    } else if (type === 'partners') {
      setPartnerId(id);
    } else if (type === 'paymentChannel') {
      setSelectedPaymentOption(val);
    } else if (type === 'fund') {
      console.log(id);
      setPlanId(id);
    }
  };

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }
  return (
    <main className="card card-custom card-stretch gutter-b">
      <section
        style={{
          width: '95%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <div className="card-header">
          <div
            className="mt-10"
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {alert?.alertMessage && <Alert alert={alert} />}
            <h1>Recurring Payments </h1>
          </div>
          <div
            style={{
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <form
              className="d-flex flex-wrap"
              style={{
                marginTop: '1.5rem',
                marginBottom: '1.5rem',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              {partners && (
                <SelectBox
                  name="Partner Filters"
                  options={partners}
                  width="220px"
                  setValue={setValue}
                  type="partners"
                />
              )}
              <SelectBox
                name="status filters"
                options={recurringPaymentStatus}
                width="220px"
                setValue={setValue}
                type="status"
              />
              <SelectBox
                name="Payment Channels"
                options={recurringPaymentChannels}
                width="220px"
                setValue={setValue}
                type="paymentChannel"
              />
              <SelectBox
                name="Fund"
                options={plans}
                width="220px"
                setValue={setValue}
                type="fund"
              />
            </form>
            <form
              className="d-flex flex-wrap"
              style={{
                marginTop: '1.5rem',
                marginBottom: '1.5rem',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <input
                className="form-control"
                type="text"
                placeholder="Search by name,email or phone number"
                name="plan"
                min={0}
                style={{
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  width: '80%',
                  height: '45px',
                  padding: '12px 20px 12px 20px',
                  borderRadius: '10px',
                  border: '1px solid #0071CE',
                }}
                onChange={handleSearch}
              />

              <button
                className="btn btn-primary px-6 mb-2 mr-1"
                onClick={e => {
                  e.preventDefault();
                  searchForRecurringPayments(
                    partnerId,
                    status,
                    paymentChannel,
                    planId,
                  );
                }}
                style={{
                  color: 'white',
                  background: '#0071CE',
                  width: '165px',
                  border: '1px solid #0071CE',
                  borderRadius: '5px',
                }}
              >
                Search
                <span className="svg-icon svg-icon-light ml-2">
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/General/Search.svg')}
                    alt="search icon"
                  />
                </span>
                {loadingData && <span className="ml-3 spinner spinner-white" />}
              </button>
            </form>
          </div>
        </div>
      </section>
      <section className="card-body mt-3 mb-10 pt-0 pb-3">
        <div className="tab-content">
          <div>
            <table className="table table-responsive table-head-custom table-head-bg  table-border table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  {tableHeaders.map((header, index) => (
                    <th
                      key={index}
                      style={{ minWidth: '150px', textAlign: 'center' }}
                      className="pl-7"
                    >
                      <span style={{ color: '#3699FF' }}> {header} </span>
                    </th>
                  ))}
                  {grantPermission(
                    AdminPermissions.MANIPULATE_RECURRING_PAYMENTS,
                  ) && (
                    <th
                      style={{ minWidth: '150px', textAlign: 'center' }}
                      className="pl-7"
                    >
                      <span style={{ color: '#3699FF' }}>Status </span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {recurringPayments.map(eachRecurringPayment => (
                  <RecurringPaymentTable
                    key={eachRecurringPayment.id}
                    {...eachRecurringPayment}
                    getAllRecurringPayments={getAllRecurringPayments}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
          {totalPages && totalPages > 0 ? (
            <Pagination
              page={page}
              count={totalPages}
              onChange={handleChangePage}
            />
          ) : (
            ''
          )}
          {totalRecord && totalRecord > 10 ? (
            <NumberOfRows
              handleChangeLimit={handleChangeLimit}
              totalRecord={totalRecord}
            />
          ) : (
            ''
          )}
        </div>
      </section>
    </main>
  );
}

export default RecurringPayments;
